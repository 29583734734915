import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material';
import GeneralService from '../../../services/general';
import ReportService from '../../../services/report';
import CustomInput from '../../components/general/CustomInput';
import CustomButton from '../../components/general/CustomButton';
import CustomSelect from '../../components/general/CustomSelect';
import { CODES } from '../../../utils/codesHTTP'
import { simpleAlerts } from '../../../utils/alerts';
import { getDateOL, minutesDiff, } from '../../../utils/function';
import DynamicTitle from '../../../utils/DynamicTitle';
import Swal from 'sweetalert2';

const ReportLegajo = ({ userToken, setOpenLoader, setTitleLoader, }) => {
    const [search, setSearch] = useState("");
    const [companies, setCompanies] = useState(null);
    const [clients, setClients] = useState(null);

    const [valueCompany, setValueCompany] = useState(null);
    const [valueClient, setValueClient] = useState(null);

    const [activos, setActivos] = useState(true);
    const [cesados, setCesados] = useState(true);

    const [dateInit, setDateInit] = useState(getDateOL());
    const [dateEnd, setDateEnd] = useState(getDateOL('', 'short', 1));

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        setValueClient(null);
        setClients(null);
        if (+valueCompany > 0) {
            getCliente();
        }
    }, [valueCompany])

    const init = async () => {
        setOpenLoader(true);
        setTitleLoader("Cargando información ...");
        Promise.all([
            getEmpresas(),
        ]).then(() => { setOpenLoader(false); setTitleLoader(null); });
    }

    const getEmpresas = async () => {
        try {
            const result = await GeneralService.getEmpresas()
            if (result.status === CODES.SUCCESS_200) {
                setCompanies(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getEmpresas: ${error}`);
        }
    }

    const getCliente = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader("Buscando lista de clientes ...");
            const result = await GeneralService.getCliente(valueCompany)
            if (result.status === CODES.SUCCESS_200) {
                setClients(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getCliente: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    const exportReporteLegajo = async () => {
        let resultSwal

        if (!search && (!valueClient || valueClient === 0)) {
            resultSwal = await Swal.fire({
                title: `Seguro de seleccionar un cliente o ingresar número de documento`,
                text: `El reporte tardara varios minutos`,
                showCancelButton: true,
                confirmButtonText: "Si",
                cancelButtonText: "No",
            });
        }

        if (resultSwal?.isConfirmed || !resultSwal) {
            try {
                const body = {
                    id_cliente: valueClient,
                    numdoc: search,
                    id_estado: (
                        (activos && cesados) ?
                            3
                            : (activos) ?
                                1
                                : (cesados) ?
                                    2
                                    : 1
                    )
                }
                setOpenLoader(true);
                setTitleLoader('Generando Reporte ...');
                const result = await ReportService.exportReporteLegajo(body);
                if (result.status === CODES.SUCCESS_200) {
                    const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'DatosLegajo.xlsx';
                    link.click();
                    URL.revokeObjectURL(url);
                    simpleAlerts('Reporte generado correctamente', 'success')
                } else {
                    simpleAlerts('No se pudo generar reporte', 'error')
                }
                return []
            } catch (error) {
                console.log(`Error en generateSCTR: ${error}`);
            } finally {
                setOpenLoader(false);
                setTitleLoader(null);
            }
        }
    }

    const exportReporteAnalista = async () => {
        const totalMinute = (7*24*60);
        if ((!dateInit || dateInit==="") || (!dateEnd || dateEnd==="")){
            simpleAlerts("La fecha inicial no puede ser mayor a la final", "warning");
            return;
        }
        if (dateInit > dateEnd){
            simpleAlerts("La fecha inicial no puede ser mayor a la final", "warning");
            return;
        }
        if (minutesDiff(dateInit, dateEnd) >= totalMinute){
            simpleAlerts("El maximo es 7 dias", "warning");
            return;
        }
        try {
            const body = {
                fechaInicio: dateInit,
                fechaFin: dateEnd,
            }
            setOpenLoader(true);
            setTitleLoader('Generando Reporte ...');
            const result = await ReportService.exportReporteAnalista(body);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'DatosAnalista.xlsx';
                link.click();
                URL.revokeObjectURL(url);
                simpleAlerts('Reporte generado correctamente', 'success')
            } else {
                simpleAlerts('No se pudo generar reporte', 'error')
            }
            return []
        } catch (error) {
            console.log(`Error en exportReporteAnalista: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    return (
        <div className='row justify-content-center'>
            <DynamicTitle title="Reporte de legajos" />
            <div className="col-md-7 col-sm-11" style={{ border: "solid 1px #21618c", borderRadius: "25px", padding: "20px" }}>
                <label style={{ fontSize: "20px", fontWeight: "bolder", letterSpacing: "2px", }}>Reporte General:</label>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} md={5}>
                        <CustomSelect
                            title={"Empresas"}
                            placeholder={'Seleccionar Tipo Legajo'}
                            options={companies}
                            value={valueCompany}
                            onChange={(value, event) => setValueCompany(value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <CustomSelect
                            title={"Clientes"}
                            placeholder={'Seleccionar Tipo Legajo'}
                            options={clients}
                            value={valueClient}
                            onChange={(value, event) => setValueClient(value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <input type="checkbox" id="activos" name="activos" checked={activos} value={activos} onChange={() => setActivos(!activos)} />
                                &nbsp;&nbsp;
                                <label htmlFor="activos"> Activos</label>
                            </div>
                            <div>
                                <input type="checkbox" id="cesados" name="cesados" checked={cesados} value={cesados} onChange={() => setCesados(!cesados)} />
                                &nbsp;&nbsp;
                                <label htmlFor="cesados"> Cesados</label>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <CustomInput
                            title="Nro. Documento"
                            placeholder={"Buscar por documento"}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <CustomButton
                            title='Buscar trabajador por documento'
                            styleType={'outline'}
                            variant={'success'}
                            value={"Descargar Reporte"}
                            onClick={exportReporteLegajo}
                            style={{ width: '100%' }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div className="col-md-7 col-sm-11" style={{ border: "solid 1px #21618c", borderRadius: "25px", padding: "20px", marginTop: "10px", }}>
                <label style={{ fontSize: "20px", fontWeight: "bolder", letterSpacing: "2px", }}>Reporte por analista:</label>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="end"
                    spacing={2}
                >
                    <Grid item xs={12} md={5}>
                        <CustomInput
                            title={'Fecha Inicio'}
                            type={'date'}
                            value={dateInit}
                            onChange={(e) => setDateInit(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <CustomInput
                            title={'Fecha Fin'}
                            type={'date'}
                            value={dateEnd}
                            onChange={(e) => setDateEnd(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <CustomButton
                            title='Buscar trabajador por documento'
                            styleType={'outline'}
                            variant={'success'}
                            value={"Descargar Reporte"}
                            onClick={exportReporteAnalista}
                            style={{ width: '100%' }}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default ReportLegajo