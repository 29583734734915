import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class StaffService {
    async getInformationLegajo(queryParameters) {
        const ruta = `${API_URL}/worker/legajo`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            params: queryParameters,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async saveListaNegra(body) {
        const ruta = `${API_URL}/listanegra/registrar`;
        const headers = await headerRequest();
        return axios.post(ruta, body, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }
}

export default new StaffService();
