// import React from 'react';
// import { Select } from "antd";
// const { Option } = Select;

// const CustomSelect = (props) => {
//     const { customtStyle, placeholder, mode, readonly, width, size, options, value, name, onChange, required, onKeyDown } = props;

//     return (
//         <Select
//             mode={mode || "single"}
//             maxTagCount={'responsive'}
//             disabled={readonly || false}
//             className="select"
//             size={size || 'small'}
//             optionFilterProp="children"
//             name={name || ''}
//             popupClassName='my-drop-down'
//             value={value || 0}
//             onChange={onChange}
//             style={{
//                 ...customtStyle,
//                 width: width || '100%',
//                 fontSize: '15px',
//             }}
//             required={required || false}
//             showSearch
//             onKeyDown={onKeyDown}
//         >
//             <Option value={0}><em>{placeholder || 'Seleccionar'}</em></Option>
//             {options?.map((opt, idx) => {
//                 return (
//                     <Option key={idx + '-' + opt?.value?.toString() + '-' + opt?.label?.replace(/\s+/g, '')} value={opt?.value}>
//                         {opt?.value} - {opt?.label}
//                     </Option>
//                 )
//             })}
//         </Select>
//     );
// };

// export default CustomSelect;

import React, { } from 'react';
import { Select } from "antd";
const { Option } = Select;

const MainSelect = (props) => {
    const { mode, title, readonly, placeholder, width, size, options, value, name, onChange, required } = props;
    return (
        <div
            className='fieldset'
        >
            <legend className='title-legend'>{title}</legend>
            <Select
                mode={mode || "single"}
                disabled={readonly || false}
                className="select"
                size={size || 'middle'}
                placeholder={placeholder || 'Seleccionar'}
                optionFilterProp="children"
                showSearch
                name={name || ''}
                popupClassName='my-drop-down'
                value={value || 0}
                onChange={onChange}
                style={{ width: width || '100%' }}
                required={required || false}
            >
                <Option value={0}><em>Seleccionar</em></Option>
                {options?.map((opt, idx) => {
                    return (
                        <Option key={opt?.value?.toString() + opt?.label?.replace(/\s+/g, '')} value={opt?.value}>
                            {opt?.label}
                        </Option>
                    )
                })}
            </Select>
        </div>
    );
};

export default MainSelect;