import React from 'react';

const CustomInfo = (props) => {
    const { title, value, name, } = props;

    return (
        <div>
            <label className="form-label title-legend">
                {title}
            </label>
            <label
                className="form-control text"
                name={name || ''}
                style={{ height: '1.725rem', backgroundColor: "#D5DBDB" }}
            >
                {value || ''}
            </label>
        </div>
    );
};

export default CustomInfo;