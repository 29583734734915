import React from 'react'
import '../../../assets/styles/login.css';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import loginImg from '../../../assets/img/signup-image.jpg';
import AuthService from "../../../services/auth";
import { CODES } from "../../../utils/codesHTTP";
import { clearBrowserRefresh } from "../../../utils/function";
import { Global } from '../../../utils/general';

const Login = ({ setUserToken, setOpenLoader, setTitleLoader, }) => {
    const info = Global.info;
    const infoTime = Global.infoTime;

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setOpenLoader(true);
            const data = new FormData(event.currentTarget);
            const result = await AuthService.signIn(data);
            if (result?.status === CODES.SUCCESS_200) {
                const expirationDate = new Date(new Date().getTime() + result?.data?.expires_in * 1000);
                localStorage.setItem(infoTime, expirationDate);
                localStorage.setItem(info, JSON.stringify(result?.data));
                setUserToken(result?.data);
                clearBrowserRefresh()
            }
        } catch (error) {
            console.log(`Error login: ${error}`)
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    };

    return (
        <div>
            <div className="body-login">
                <div className="main">
                    <section className="signup">
                        <div className="container-login">
                            <div className="signup-content">
                                <div className="signup-form">
                                    <h2 className="form-title h2-login">Login</h2>
                                    <form method="POST" className="register-form" id="register-form" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name"
                                                className="label-login"
                                            ><PersonIcon fontSize='small' /></label>
                                            <input
                                                type="text"
                                                name="usuario"
                                                id="name"
                                                className="input-login"
                                                placeholder="Usuario"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="pass"
                                                className="label-login"
                                            ><LockIcon fontSize='small' /></label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="pass"
                                                className="input-login"
                                                placeholder="Password"
                                            />
                                        </div>
                                        <div className="form-group form-button">
                                            <input
                                                type="submit"
                                                name="signup"
                                                id="signup"
                                                className="form-submit input-login"
                                                value="Iniciar sesion"
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="signup-image">
                                    <figure className="figure-login">
                                        <img className="img-login" src={loginImg} alt="sing up image" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Login