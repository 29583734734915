import { BorderColor } from '@mui/icons-material'
import React from 'react'

const CustomButton = ({
    size = 'sm',
    style = null,
    styleType = '',
    color = '',
    variant = 'primary',
    className = '',
    title = '',
    value,
    onClick,
    type,
    backgroundColor = '', //Agregado para recibir color de fondo para el boton
    borderColor = '' //Agregado para recibir color de borde para el boton
}) => {

    return (
        <button
            type={`${type || 'button'}`}
            title={`${title}`}
            className={`
            btn 
            btn-${size} 
            ${color ? '' : (`btn${styleType && '-' + styleType}-${variant}`)} 
            ${className} 
            `}
            style={{
                // paddingTop: 1.5,
                // paddingBottom: 1.5,
                // paddingLeft: 2.5,
                // paddingRight: 2.5,
                // fontSize: '0.7rem',
                ...style,
                paddingTop: (size === 'sm' && 1.5),
                paddingBottom: (size === 'sm' && 1.5),
                paddingLeft: (size === 'sm' && 2.5),
                paddingRight: (size === 'sm' && 2.5),
                color: color,
                border: color && `solid 1px ${color}`,
                backgroundColor: backgroundColor,
                borderColor: borderColor
            }}
            onClick={onClick}
        >
            {value}
        </button>
    )
}

export default CustomButton