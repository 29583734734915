import React, { useState } from 'react'
import { useNavigate, } from 'react-router-dom';
import { Grid, TextField, InputAdornment, IconButton, Button } from '@mui/material';
import AuthService from '../../../services/auth';
import KeyIcon from '@mui/icons-material/Key';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CODES } from '../../../utils/codesHTTP'
import { simpleAlerts } from '../../../utils/alerts';
import { Global } from '../../../utils/general';
import DynamicTitle from '../../../utils/DynamicTitle';
import _ from 'lodash';

const ChangePassword = ({ userToken, setOpenLoader, setTitleLoader, }) => {
    const id_usuarioLogin = userToken.user.id_usuario;
    const navigate = useNavigate();

    const pathInit = Global.path;
    const info = Global.info;
    const infoTime = Global.infoTime;
    const [oldPassword, setOldPassword] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

    const [viewOldPassword, setViewOldPassword] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

    const handlePasswordChange = (value) => {
        // Actualizar el estado y mostrar mensajes de error si es necesario
        setPassword(value);

        // Ejemplo de validación básica
        // const regex = /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)(?=.*[a-z])(?!.*[ \/]).{6,}$/;
        const regex = /^(?=.*[@#$%&*])(?=.*\d)(?=.*[a-z])(?!.*[ \/]).{6,}$/;
        if (!regex.test(value)) {
            setErrorPassword('La contraseña debe tener al menos 6 caracteres, un número y un carácter especial.');
        } else {
            setErrorPassword('');
        }
    };

    const handleConfirmPasswordChange = (value) => {
        // Comparar con la contraseña
        if (value !== password) {
            setErrorConfirmPassword('Las contraseñas no coinciden.');
        } else {
            setErrorConfirmPassword('');
        }

        // Actualizar el estado
        setConfirmPassword(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password === oldPassword) {
            simpleAlerts('La nueva clave no debe ser igual a la anterior', "error")
            return;
        }
        if (password !== confirmPassword) {
            simpleAlerts('Las clave y la confirmación no coinciden', "error")
            return;
        }

        let body = {
            id_usuario: id_usuarioLogin,
            oldClave: oldPassword,
            clave: password,
            clave_confirmation: confirmPassword
        }
        try {
            setTitleLoader('Actualizando');
            setOpenLoader(true);
            const result = await AuthService.updatePassword(body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Exito en la actualización', 'success');
                setTimeout(() => {
                    localStorage.removeItem(info);
                    localStorage.removeItem(infoTime);
                    navigate(`${pathInit}`);
                    window.location.reload();
                }, 1500);
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en la actualización', 'error');
            }
            return []
        } catch (error) {
            console.log(`Error en getUsuarios: ${error}`);
        } finally {
            setTitleLoader(null);
            setOpenLoader(false);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <DynamicTitle title="Cambio de contraseña" />
            <Grid container justifyContent="center" alignItems="center" m={4} spacing={2}>
                <Grid item xs={7}>
                    <TextField
                        id="input-old-password"
                        label="Ingrese contraseña anterior"
                        placeholder="Contraseña anterior"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyIcon fontSize='small' />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="delete" size="small"
                                        onClick={() => { setViewOldPassword(!viewOldPassword) }}
                                    >
                                        {viewOldPassword ?
                                            <VisibilityIcon fontSize='inherit' />
                                            : <VisibilityOffIcon fontSize="inherit" />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        type={viewOldPassword ? 'text' : 'password'}
                        variant="standard"
                        style={{ width: '70%' }}
                        value={oldPassword || ''}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        id="input-password"
                        label="Ingrese nueva contraseña"
                        placeholder="nueva contraseña"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {viewPassword ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="delete" size="small"
                                        onClick={() => { setViewPassword(!viewPassword) }}
                                    >
                                        {viewPassword ?
                                            <VisibilityIcon fontSize='inherit' />
                                            : <VisibilityOffIcon fontSize="inherit" />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        type={viewPassword ? 'text' : 'password'}
                        variant="standard"
                        style={{ width: '70%' }}
                        value={password || ''}
                        onChange={(e) => handlePasswordChange(e.target.value)}
                        error={Boolean(errorPassword)}
                        helperText={errorPassword}
                    />
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        id="input-confirm-password"
                        label="confirme contraseña"
                        placeholder="confirme contraseña"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {viewConfirmPassword ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="delete" size="small"
                                        onClick={() => { setViewConfirmPassword(!viewConfirmPassword) }}
                                    >
                                        {viewConfirmPassword ?
                                            <VisibilityIcon fontSize='inherit' />
                                            : <VisibilityOffIcon fontSize="inherit" />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        type={viewConfirmPassword ? 'text' : 'password'}
                        variant="standard"
                        style={{ width: '70%' }}
                        value={confirmPassword || ''}
                        onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                        error={Boolean(errorConfirmPassword)}
                        helperText={errorConfirmPassword}
                    />
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button
                        type="submit"
                        variant="contained"
                    >
                        Actualizar Contraseña
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default ChangePassword