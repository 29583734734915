import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class FileLegajoService {
    async saveLegajos(body) {
        const ruta = `${API_URL}/archive-s3`;
        const headers = await headerRequest();
        return axios.post(ruta, body, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            }
        }).catch((error) => {
            return error;
        });
    }

    async getFilesByWorker(trabajadorEmpresa_id, dateInit, dateEnd, typeLegajo) {
        const ruta = `${API_URL}/files/worker/${trabajadorEmpresa_id}/${dateInit}/${dateEnd}/${typeLegajo}`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async getFilesByWorkerAUD(trabajadorEmpresa_id, dateInit, dateEnd, typeLegajo) {
        const ruta = `${API_URL}/files/worker/AUD/${trabajadorEmpresa_id}/${dateInit}/${dateEnd}/${typeLegajo}`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async downloadFilesLegajo(downloadFilesS3) {
        const ruta = `${API_URL}/file-s3?archivo=${downloadFilesS3}`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
            responseType: 'blob'
        })
    }

    async deleteFile(id_archivo) {
        const ruta = `${API_URL}/file-s3/${id_archivo}`;
        const headers = await headerRequest();
        return axios.delete(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async viewFileS3(archivo) {
        const ruta = `${API_URL}/file-s3/view?archivo=${archivo}`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
            responseType: 'blob'
        });
    }
}

export default new FileLegajoService();
