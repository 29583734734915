import React, { useState, useEffect } from 'react'
import FileLegajoService from '../../../../services/fileLegajo';
import CustomModal from '../../../components/general/CustomModal';
import InformationVerifyLegajo from '../../../components/RegisterLegajo/AddLegajo/InformationVerifyLegajo';
import { getDateOL } from '../../../../utils/function';
import { simpleAlerts } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/codesHTTP';

const AddVerify = (props) => {
    const { open, setOpen, stateModal, typeLegajo, infoGroup, setOpenLoader, setTitleLoader, } = props;
    const [dateInit, setDateInit] = useState(getDateOL('', 'short', -30));
    const [dateEnd, setDateEnd] = useState(getDateOL());
    const [valueTypeLegajo, setValueTypeLegajo] = useState(null);
    const [detailLegajo, setDetailLegajo] = useState(null);
    const [detailLegajoAUD, setDetailLegajoAUD] = useState(null);

    const [historical, setHistorical] = useState(0)
    const handleHistorical = (event, newValue) => setHistorical(newValue);

    useEffect(() => {
        if (stateModal === 'VIEW') {
            getInfo();
        }
    }, [stateModal])

    useEffect(() => {
        setDetailLegajo(null);
        setDetailLegajoAUD(null);
    }, [valueTypeLegajo, dateInit, dateEnd])

    const cleanInfo = () => {
        setOpen(false);
    }

    const getInfo = async () => {
        Promise.all([
            getFilesByWorker(),
            getFilesByWorkerAUD(),
        ]).then();
    }

    const getFilesByWorker = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader('Buscando ...');
            const result = await FileLegajoService.getFilesByWorker(infoGroup?.id_trabajadorEmpresa, dateInit, dateEnd, (valueTypeLegajo || 0));
            if (result.status === CODES.SUCCESS_200) {
                setDetailLegajo(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getFilesByWorker: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    const getFilesByWorkerAUD = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader('Buscando ...');
            const result = await FileLegajoService.getFilesByWorkerAUD(infoGroup?.id_trabajadorEmpresa, dateInit, dateEnd, (valueTypeLegajo || 0));
            if (result.status === CODES.SUCCESS_200) {
                setDetailLegajoAUD(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getFilesByWorker: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    return (
        <CustomModal
            size={'md'}
            open={open}
            handleClose={cleanInfo}
            formSubmit={null}
            title={'Registro de legajos'}
            subTitle={'Verificación de archivo de legajos'}
            bodyModal={
                <InformationVerifyLegajo
                    getInfo={getInfo}
                    dateInit={dateInit}
                    setDateInit={setDateInit}
                    dateEnd={dateEnd}
                    setDateEnd={setDateEnd}
                    valueTypeLegajo={valueTypeLegajo}
                    setValueTypeLegajo={setValueTypeLegajo}
                    typeLegajo={typeLegajo}
                    detailLegajo={detailLegajo}
                    detailLegajoAUD={detailLegajoAUD}
                    setOpenLoader={setOpenLoader}
                    setTitleLoader={setTitleLoader}
                    historical={historical}
                    handleHistorical={handleHistorical}
                />
            }
        // buttonActions={ }
        />
    )
}

export default AddVerify