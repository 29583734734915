import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class LegajoService {
    async getTypeLegajo() {
        const ruta = `${API_URL}/legajo/type`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async saveTypeLegajo(body) {
        const ruta = `${API_URL}/legajo/saveLegajo`;
        const headers = await headerRequest();
        return axios.post(ruta, body, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async deleteTypeLegajos(body) {
        const ruta = `${API_URL}/legajo/deleteLegajo`;
        const headers = await headerRequest();
        return axios.delete(ruta, {
            headers: headers,
            data: body
        }).catch((error) => {
            return error;
        });
    }
}

export default new LegajoService();
