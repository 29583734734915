import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material';
import LegajoService from '../../../services/legajo'
import CustomInput from '../../components/general/CustomInput';
import CustomButton from '../../components/general/CustomButton';
import BodyTypeLegajo from '../../components/TypeLegajo/BodyTypeLegajo';
import { CODES } from '../../../utils/codesHTTP'
import DynamicTitle from '../../../utils/DynamicTitle';
import _ from 'lodash';

const row = {
    tipoLegajo: "",
    edit: 1
}

const TypeLegajo = ({ userToken, setOpenLoader, setTitleLoader, }) => {
    const [dataTypeLegajo, setDataTypeLegajo] = useState(null);
    const [search, setSearch] = useState("");

    useEffect(() => {
        getTypeLegajo();
    }, [])

    const getTypeLegajo = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader("Listando tipos de legajo ...");
            const result = await LegajoService.getTypeLegajo();
            if (result.status === CODES.SUCCESS_200) {
                setDataTypeLegajo(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getTypeLegajo: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    const addTipeLegajo = () => {
        let updInfo = _.cloneDeep(dataTypeLegajo);
        updInfo.unshift(row);
        return setDataTypeLegajo(updInfo);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <DynamicTitle title="Tipos de legajos" />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} md={7} sx={{ padding: "0 10px" }} mb={2}>
                    <div style={{ display: "flex", alignItems: "end", gap: 10 }}>
                        <div style={{ flex: "1" }}>
                            <CustomInput
                                placeholder={"Buscar por descripción"}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <CustomButton
                            title='Buscar trabajador por documento'
                            styleType={'outline'}
                            variant={'success'}
                            value={"agregar"}
                            onClick={addTipeLegajo}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={5} xs={8} mt={2}>
                    <BodyTypeLegajo
                        setOpenLoader={setOpenLoader}
                        setTitleLoader={setTitleLoader}
                        getTypeLegajo={getTypeLegajo}
                        search={search}
                        dataTypeLegajo={dataTypeLegajo}
                        setDataTypeLegajo={setDataTypeLegajo}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TypeLegajo