// Componente para cambiar dinámicamente el título del documento
import { useEffect } from 'react';
import { Global } from './general';

function DynamicTitle({ title }) {
    const infoName = Global.infoName;
    useEffect(() => {
        document.title = `${infoName} - ${title}`;
    }, [title]);

    return null; // Este componente no renderiza nada en el DOM
}

export default DynamicTitle;
