import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material';
import LegajoService from '../../../services/legajo'
import GeneralService from '../../../services/general';
import StaffService from '../../../services/staff';
import CustomInput from '../../components/general/CustomInput';
import CustomButton from '../../components/general/CustomButton';
import CustomSelect from '../../components/general/CustomSelect';
import AddVerify from './AddVerify/AddVerify';
import BodyVerifyLegajo from '../../components/VerifyLegajo/BodyVerifyLegajo';
import { CODES } from '../../../utils/codesHTTP'
import { simpleAlerts } from '../../../utils/alerts';
import DynamicTitle from '../../../utils/DynamicTitle';

const VerifyLegajoLegajo = ({ userToken, setOpenLoader, setTitleLoader, }) => {
    const [open, setOpen] = useState(false);
    const [companies, setCompanies] = useState(null);
    const [valueCompany, setValueCompany] = useState(null);
    const [typeLegajo, setTypeLegajo] = useState(null);
    const [search, setSearch] = useState("");
    const [stateModal, setStateModal] = useState(null);
    const [dataLegajo, setDataLegajo] = useState(null);
    const [infoGroup, setInfoGroup] = useState(null);

    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const pageSize = 20;

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        setDataLegajo(null);
    }, [valueCompany, search])

    useEffect(() => {
        getInformationLegajo();
    }, [page])

    const init = async () => {
        setOpenLoader(true);
        setTitleLoader("Cargando información ...");
        Promise.all([
            getTypeLegajo(),
            getEmpresas()
        ]).then(() => { setOpenLoader(false); setTitleLoader(null); });
    }

    const getTypeLegajo = async () => {
        try {
            const result = await LegajoService.getTypeLegajo();
            if (result.status === CODES.SUCCESS_200) {
                setTypeLegajo(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getTypeLegajo: ${error}`);
        }
    }

    const getEmpresas = async () => {
        try {
            const result = await GeneralService.getEmpresas()
            if (result.status === CODES.SUCCESS_200) {
                setCompanies(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getTypeLegajo: ${error}`);
        }
    }

    const getInformationLegajo = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader('Buscando información ...');
            const params = {
                search: (search || ''),
                page: page,
                pageSize: pageSize,
                id_empresa: valueCompany,
                pagination: 1,
            };
            const result = await StaffService.getInformationLegajo(params);
            if (result.status === CODES.SUCCESS_200) {
                setDataLegajo(result?.data?.data);
                setTotalPages(result?.data?.last_page);
            } else if (result?.response?.status === CODES.ERROR_500) {
                simpleAlerts('Error al generar la información', 'error');
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getInformationLegajo: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    const handleOpenLegajo = (info, state) => {
        setStateModal(state);
        setInfoGroup(info)
        setOpen(true);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            getInformationLegajo();
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <DynamicTitle title="Verificar legajos" />
            <AddVerify
                open={open}
                setOpen={setOpen}
                stateModal={stateModal}
                typeLegajo={typeLegajo}
                infoGroup={infoGroup}
                setOpenLoader={setOpenLoader}
                setTitleLoader={setTitleLoader}
            />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} md={4} sx={{ padding: "0 10px" }} mb={2}>
                    <CustomSelect
                        placeholder={'Seleccionar Tipo Legajo'}
                        options={companies}
                        value={valueCompany}
                        onChange={(value, event) => setValueCompany(value)}
                    />
                </Grid>
                <Grid item xs={12} md={4} sx={{ padding: "0 10px" }} mb={2}>
                    <div style={{ display: "flex", alignItems: "end", gap: 10 }}>
                        <div style={{ flex: "1" }}>
                            <CustomInput
                                placeholder={"Buscar por documento"}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                handleKeyPress={handleKeyPress}
                            />
                        </div>
                        <CustomButton
                            title='Buscar trabajador por documento'
                            styleType={'outline'}
                            variant={'success'}
                            value={"Buscar"}
                            onClick={getInformationLegajo}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={8} xs={12} mt={2}>
                    <BodyVerifyLegajo
                        handleOpenLegajo={handleOpenLegajo}
                        dataLegajo={dataLegajo}
                        totalPages={totalPages}
                        page={page}
                        setPage={setPage}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default VerifyLegajoLegajo