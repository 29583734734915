import React, { useState, useEffect } from 'react';
import "../../../assets/styles/navbar.scss";
import { useNavigate } from 'react-router-dom';
import MenuService from "../../../services/menu";
import AuthService from "../../../services/auth";
import { CODES } from '../../../utils/codesHTTP';
import { Global } from "../../../utils/general";

const NavBar = ({ setUserToken }) => {
    const navigate = useNavigate();
    const pathInit = Global.path;
    const info = Global.info;
    const infoTime = Global.infoTime;
    const [menu, setMenu] = useState(null);

    useEffect(() => {
        getMenu();
    }, []);

    const getMenu = async () => {
        try {
            const result = await MenuService.getMenu();
            if (result?.status === CODES.SUCCESS_200) {
                setMenu(result?.data)
            } else if (result?.code === 'ERR_NETWORK') {
                localStorage.removeItem(info);
                setUserToken(null)
                window.location.reload();
            }
            return [];
        } catch (error) {
            console.log(`Error en MenuService: ${error}`);
        }
    }

    const [activeItem, setActiveItem] = useState(null);
    const handleItemClick = (route) => {
        navigate(route);
        setActiveItem(route);
    };

    const signOut = async () => {
        try {
            const result = await AuthService.signOut();
            localStorage.removeItem(info);
            localStorage.removeItem(infoTime);
            setUserToken(null)
            window.location.href = pathInit;
            return [];
        } catch (error) {
            console.log(`Error en MenuService: ${error}`);
        }
    }

    return (
        // <header className="site-navbar" role="banner">
        //     <div className="container">
        //         <div className="row align-items-center">
        //             <div className="col-11 col-xl-2">
        //                 <h1 className="mb-0 site-logo"><a href="index.html" className="mb-0 a-navbar" style={{ color: "#21618c" }}>Legajo</a></h1>
        //             </div>
        //             <div className="col-12 col-md-10 d-none d-xl-block">
        //                 <nav className="site-navigation position-relative text-right" role="navigation">
        //                     <ul className="site-menu js-clone-nav mr-auto d-none d-lg-block">
        //                         {menu?.map((item) => {
        //                             return (
        //                                 <li
        //                                     className={activeItem === `${item.link}` ? 'active' : ''}
        //                                     key={item.label}
        //                                     onClick={() => handleItemClick(`${item.link}`)}
        //                                 >
        //                                     <a className='a-navbar'>
        //                                         <span>{item.label}</span>
        //                                     </a>
        //                                 </li>
        //                             )
        //                         })}
        //                         <li className="has-children">
        //                             <a href="#" className='a-navbar'>Varios</a>
        //                             <ul className="dropdown">
        //                                 <li
        //                                     className={activeItem === `${pathInit || ''}/change-password` ? 'active' : ''}
        //                                     onClick={() => handleItemClick(`${pathInit || ''}/change-password`)}
        //                                 >
        //                                     <a className='a-navbar'>
        //                                         <span>Actualizar clave</span>
        //                                     </a>
        //                                 </li>
        //                                 <li onClick={signOut}>
        //                                     <a className='a-navbar'>
        //                                         <span>Cerrar Sesión</span>
        //                                     </a>
        //                                 </li>
        //                             </ul>
        //                         </li>
        //                     </ul>
        //                 </nav>
        //             </div>
        //             <div className="d-inline-block d-xl-none ml-md-0 mr-auto py-3"
        //                 style={{ position: 'relative', top: '3px', }}>
        //                 <a href="#" className="site-menu-toggle js-menu-toggle text-white"><span className="icon-menu h3"></span></a></div>
        //         </div>
        //     </div>
        // </header>
        <nav className={`navbar navbar-expand-lg fixed-top site-navbar`}>
            <div className="container">
                <a className={`navbar-brand`} href="#">AppLegajo</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className={`navbar-nav me-auto fs-small`}>
                        {menu?.map((item) => {
                            return (
                                <li
                                    className={`nav-item ${activeItem === `${item.link}` ? 'active' : ''}`}
                                    key={item.label}
                                    onClick={() => handleItemClick(`${item.link}`)}
                                >
                                    <a className='nav-link'>
                                        <span>{item.label}</span>
                                    </a>
                                </li>
                            )
                        })}
                        <li className="nav-item dropdown">
                            <a
                                className={`nav-link dropdown-toggle`} href=""
                                id={`navbarDropdown-varios`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Varios
                            </a>
                            <ul
                                className={`dropdown-menu`}
                                aria-labelledby={`navbarDropdown-varios`}
                            >
                                <li
                                    className={`nav-item-menu`}
                                    onClick={() => handleItemClick(`${pathInit || ''}/change-password`)}
                                >
                                    <a className='nav-link-menu'>
                                        <span>Actualizar clave</span>
                                    </a>
                                </li>
                                <li
                                    className={`nav-item-menu`}
                                    onClick={signOut}
                                >
                                    <a className='nav-link-menu'>
                                        <span>Cerrar Sesión</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar