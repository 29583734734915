import React, { useEffect } from "react";
import { CardHeader, CardContent, CardActions, } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CustomButton from "./CustomButton";
import { Modal } from 'antd';

const CustomModal = (props) => {
    const { open, handleOpen, handleClose, title, subTitle, bodyModal, buttonActions, formSubmit, size, centrado } = props;

    useEffect(() => {
        if (open)
            if (handleOpen)
                handleOpen();
    }, [open]);

    return (
        <Modal
            title={
                <CardHeader
                    avatar={
                        <Avatar sx={{ width: 28, height: 28, bgcolor: '#21618c', }}>
                            <EngineeringIcon sx={{ fontSize: '1.3rem' }} />
                        </Avatar>
                    }
                    title={<div style={{ fontSize: '12px' }}>{title}</div>}
                    subheader={<div style={{ fontSize: '10px', fontStyle: 'italic' }}>{subTitle}</div>}
                    sx={{
                        paddingBottom: '0.3rem',
                        paddingTop: 0,
                        margin: '-0.5rem -1rem -0.1rem -1rem',
                        borderBottom: `solid 0.05rem #21618c`,
                    }}
                />
            }
            open={open}
            onOk={handleClose}
            onCancel={handleClose}
            destroyOnClose={true}
            footer={null}

            style={{ top: (centrado ? '20%' : 50), }}
            width={(size === 'lg' ? 1000 : size === 'md' ? 800 : 500)}
        >
            <form onSubmit={formSubmit} >
                <CardContent
                    sx={{
                        padding: 0,
                        margin: '0 -0.5rem',
                    }}
                >
                    {bodyModal}
                </CardContent>

                <CardActions
                    sx={{
                        margin: '0.5rem -0.5rem -1rem -1rem',
                        borderTop: `solid 0.05rem #21618c`,
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    {buttonActions}
                    <CustomButton
                        variant={'danger'}
                        value={'Cerrar'}
                        onClick={handleClose}
                    />
                </CardActions>
            </form>
        </Modal >
    )
}

export default CustomModal;