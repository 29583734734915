import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
class ReportService {
    async exportReporteLegajo(body) {
        const ruta = `${API_URL}/report/worker-legajo`;
        const headers = await headerRequest();
        return axios.post(ruta, body, {
            headers: headers,
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }
    
    async exportReporteAnalista(body) {
        const ruta = `${API_URL}/report/analyst-legajo`;
        const headers = await headerRequest();
        return axios.post(ruta, body, {
            headers: headers,
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }
}

export default new ReportService();