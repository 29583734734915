import React from 'react';

const CustomInput = (props) => {
    const { title, readonly, placeholder, type, value, required, name, onChange, style, styleTitle, handleKeyPress, propsInput } = props;
    // const { maxlength, minlength, pattern, step, disabled, className, id,} = propsInput || {}; // Extraer maxlength y minlength de propsInput

    return (
        <div>
            {title &&
                <label className="form-label title-legend">
                    {title}
                </label>
            }
            <input
                className="form-control text"
                type={type || 'text'}
                value={value || ''}
                onChange={onChange}
                name={name || ''}
                placeholder={placeholder || ''}
                required={required || false}
                readOnly={readonly || false}
                {...propsInput}  // Propiedades adicionales
                //maxLength={maxlength || null} // Establecer maxLength con el valor de propsInput.maxlength
                //minLength={minlength || null} // Establecer minLength con el valor de propsInput.minlength
                style={{ ...style }}
                onKeyPress={handleKeyPress}
            />
        </div>
    );
};

export default CustomInput;